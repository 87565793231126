<template>
  <div>

    <div class="uk-align-left uk-margin-remove-bottom">
      <h1 class="uk-text-left uk-margin-remove"><router-link :to="{ name: 'Users' }" style="text-decoration: none;" class="uk-flex uk-flex-middle"><span style="min-width:44px" uk-icon="icon: chevron-left; ratio: 2.2"></span><span>{{ $t('Pages.InviteUser.Title') }}</span></router-link></h1>
    </div>
    <br>
    <br>
    <hr />

    <div class="uk-text-left">

      <div class="uk-container uk-container-small">
        <div class="uk-grid-divider" uk-grid>
          <div class="uk-width-expand@m">
            <div class="uk-container uk-margin-large-top">
              <form class="uk-form-horizontal uk-margin-large uk-text-left" @submit.prevent="inviteUser();">

                <div class="uk-margin-small">
                  <label class="uk-form-label" for="form-horizontal-text">{{$t("Pages.InviteUser.Invite")}}</label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" :class="{ 'invalid-border': errors.email }" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Email')" v-model="form.email" @blur="validateEmail()" @focus="errors.email=''">
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.email" uk-icon="icon: warning"></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.email" >{{this.errors.email}}</span>
                    </div>
                  </div>
                </div>

                <hr />
                
              </form>
              <div class="uk-flex uk-flex-center">
                  <router-link :to="{ name: 'Users'}">
                      <button class="uk-button uk-button-default uk-margin-small-right">{{$t('Actions.Cancel')}}</button>
                  </router-link> 
                  <button v-if="!isSaving" class="uk-button uk-button-primary uk-margin-small-left" @click.prevent="inviteUser();">{{ $t('Pages.InviteUser.Invite') }}</button>
                  <button v-else class="uk-button uk-button-primary uk-margin-small-right" disabled><div uk-spinner="ratio: 0.5"></div></button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import Notification from '@/components/util/Notification';

export default {
  computed: {
    ...mapState({
      users: state => state.venom.org?.users || []
    })
  },
  data () {
      return {
        form: {
          email: ''
        },
        errors: {
          email: ''
        },
        orgId: this.$route.params.id,
        isSaving: false
      }
  },
  methods: {
    async inviteUser () {
      this.validateEmail();
      if (this.errors.email) return false;
      if (!this.form.email) {
        this.errors.email = this.$t('Generic.Errors.InvalidEmail')
        return
      }
      const existingUser = this.findUser(this.form.email);
      if (existingUser) {
        Notification.showNotification(this.$t("Pages.InviteUser.errors.UserAlreadyAdded"), this.$t(("Pages.InviteUser.errors.UserAlreadyAddedDesc")).replace('{{email}}', this.form.email), 'error');
        return false;
      } else {
        await this.addUserToOrg(this.form.email);
      }
      return true
    },
    async addUserToOrg (email) {
      this.isSaving = true;
      const response = await this.$store.dispatch('inviteUser', { orgId: this.orgId, email});
      this.isSaving = false;

      if (response.cmsUser?.email) {
        Notification.showNotification(this.$t("Pages.InviteUser.success.InviteSuccessful"), this.$t("Pages.InviteUser.success.InviteSuccessfulDesc").replace('{{email}}', email));
        this.clearForm();
        this.$router.push({ name: 'OrgUserProfile', params: { id: this.orgId, userId: response.cmsUser.id } });
      } else if (response.invitedCMSUser?.id) {
        Notification.showNotification(this.$t("Pages.InviteUser.errors.UserMustHaveCMSAccount"), this.$t("Pages.InviteUser.errors.UserMustHaveCMSAccountDesc").replace('{{email}}', email), 'error');
        this.clearForm();
      }
    },
    validateEmail () {
      if (!this.form.email.length) {
          return false;
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.form.email).toLowerCase())) {
          this.errors.email = false;
      } else {
          this.errors.email = this.$t('Generic.Errors.InvalidEmail');
      }
    },
    findUser(email) {
      return this.users.find(user => user.email === email);
    },
    clearForm () {
      this.form.email = ''
      this.errors.email = ''
    }
  }
}
</script>

<style scoped>

.uk-button-default {
  color: var(--text-color);
  background: var(--app-shadow-color);
}
.invalid-icon {
    color: #f0506e;
}
</style>